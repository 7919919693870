//
// Page-title
//

.page-title-box {
  padding-bottom: $grid-gutter-width;

  .page-title {
    font-size: 18px;
    font-weight: 700;
  }

  .breadcrumb {
    padding: 0rem;
  }

  .dropdown {
    .show.header-item {
      background-color: rgba($gray-100, 0.1);
    }
  }
}
