@import "./icons.scss";
@import "./bootstrap.scss";
@import "./react-bs-datatable.scss";
@import "./vendor/react-pro-sidebar.scss";
@import "./vendor/react-datepicker.scss";
@import "./vendor/bootstrap.scss";
@import "./app.scss";
@import "./print.scss";
@import "./custom-utilities";



body {
  font-family: "Source Sans Pro", "Arial", sans-serif;

  * {
    outline: none;
  }
}
#sidebar-menu {
  ul {
    li {
      a {
        i {
          min-width: 2rem;
        }
      }
    }
  }
}

.fa,
.fas {
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
}
[class*=" ti-"],
[class^="ti-"] {
  font-family: themify;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.noti-icon .badge {
  left: 23px;
}
.dataTables_filter,
.dataTables_paginate {
  float: right;
}
.rdw-editor-main {
  border: 1px solid $gray-300;
  height: 239px;
}
.dz-message {
  text-align: center;
  margin: 2em 0;
}

.fcYNFc,
.sc-AxmLO.gmtmqV {
  background-color: $card-bg !important;
  margin: 0 20px 0 0 !important;
}
.task-box {
  border: 1px solid $border-color;
}

.ReactModal__Overlay {
  z-index: 1001 !important;
}

.external-event {
  &:hover {
    cursor: pointer;
  }
}
a:hover {
  cursor: pointer;
}

.input-group-append {
  z-index: 0;
}
.input-color {
  color: $input-color !important;
}
.sketch-picker {
  position: absolute;
  z-index: 1;
}
.rangeslider__fill {
  background-color: $primary !important;
}

.flatpickr-months .flatpickr-month {
  background: $primary !important;
}
.flatpickr-weekdays {
  background: $primary !important;
}
span.flatpickr-weekday {
  background: $primary !important;
}
.flatpickr-current-month .flatpickr-monthDropdown-months {
  background: $primary !important;
}
span.flatpickr-weekday {
  color: $white !important;
}
.flatpickr-day.selected {
  background: $primary !important;
}

//ViewPort
.min-vh-50 {
  min-height: 50vh;
}
//Dropdown
.dropup .dropdown-menu {
  top: auto !important;
  bottom: 100% !important;
  transform: translate3d(5px, 5px, 0px) !important;
}

//form editor
.rdw-editor-toolbar,
.rdw-editor-main {
  border: 1px solid #ccc !important;
}

.dropzone:focus {
  outline: none !important ;
}
//dropdown
.dropdown-menu.dropdown-menu-right {
  right: 0 !important;
  left: auto !important;
}
.card-columns {
  column-count: 3;
  grid-column-gap: 1.25rem;
  -webkit-column-gap: 1.25rem;
  grid-column-gap: 24px;
  column-gap: 24px;
  orphans: 1;
  widows: 1;
}
.ion-ios-checkmark-circle-outline {
  font-size: 3.5rem;
  line-height: 56px;
}
label {
  margin-bottom: 0.5rem;
}
.DraftEditor-editorContainer {
  margin-left: 14px !important;
}

//Color Theme
.bg-primary {
  --bs-bg-opacity: 1;
  background-color: $k-dark-blue !important;
}

.bg-secondary {
  --bs-bg-opacity: 1;
  background-color: $k-gray-50 !important;
}

.bg-success {
  --bs-bg-opacity: 1;
  background-color: $success !important;
}

.bg-info {
  --bs-bg-opacity: 1;
  background-color: $k-light-blue !important;
}

.bg-warning {
  --bs-bg-opacity: 1;
  background-color: $warning !important;
}

.bg-danger {
  --bs-bg-opacity: 1;
  background-color: $danger !important;
}

.bg-yellow {
  --bs-bg-opacity: 1;
  // background-color: #ffdd15 !important;
  background-color: #ffdd15 !important;
  color: #5b626b;
}

//Text Color
.text-primary {
  --bs-bg-opacity: 1;
  color: $k-dark-blue !important;
}

.text-secondary {
  --bs-bg-opacity: 1;
  color: $k-gray-50 !important;
}

.text-success {
  --bs-bg-opacity: 1;
  color: $success !important;
}

.text-info {
  --bs-bg-opacity: 1;
  color: $k-light-blue !important;
}

.text-warning {
  --bs-bg-opacity: 1;
  color: $warning !important;
}

.text-danger {
  --bs-bg-opacity: 1;
  color: $danger !important;
}

.text-content-loading {
  --bs-bg-opacity: 1;
  color: $k-blue !important;
}
