//
// _demos.scss
//

// Demo Only
.button-items {
  margin-left: -8px;
  margin-bottom: -12px;

  .btn {
    margin-bottom: 12px;
    margin-left: 8px;
  }
}

// Lightbox

.mfp-popup-form {
  max-width: 1140px;
}

// Modals

.bs-example-modal {
  position: relative;
  top: auto;
  right: auto;
  bottom: auto;
  left: auto;
  z-index: 1;
  display: block;
}

// Grid

.grid-structure {
  .grid-container {
    background-color: $gray-100;
    margin-top: 10px;
    font-size: 0.8rem;
    font-weight: $fw-medium;
    padding: 10px 20px;
  }
}
