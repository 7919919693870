//
// Extras pages.scss
//
// FAQ

.faq {
  position: relative;
  .accordion-header {
    border: none;
    background: transparent;
  }
}

.accordion {
  .accordion-item {
    border-bottom: 1px solid $gray-200 !important;
    border-radius: 4px !important;
    box-shadow: none;
  }

  .accordion-button {
    font-weight: $fw-medium;
  }
}

.faq[data-bs-toggle="collapse"] {
  &:after {
    content: "\F0377";
    display: block;
    font-family: "Material Design Icons";
    font-size: 20px;
    color: $text-muted;
    position: absolute;
    right: 20px;
    font-weight: $fw-medium;
    top: 50%;
    background-image: none;
    transform: translateY(-50%);
  }
  &.collapsed::after {
    content: "\F0419";
  }
}

.loading-bg {
  background-color: $k-blue;
}

.content-landing {
  border-top: $k-orange solid 1.5px;
  min-height: 100vh;
}
