//
// _badge.scss
//

.badge {
  &[href] {
    &:hover,
    &:focus {
      color: $white;
    }
  }
}

// Soft badge
@mixin badge-variant-soft($bg) {
  color: $bg;
  background-color: rgba($bg, 0.18);

  &[href] {
    &:hover,
    &:focus {
      color: $bg;
      text-decoration: none;
      background-color: rgba($bg, 0.4);
    }
  }
}

@each $color, $value in $theme-colors {
  .badge {
    &.bg-#{$color} {
      &[href] {
        &:hover,
        &:focus {
          background-color: darken($value, 4%) !important;
        }
      }
    }
  }

  .badge {
    &.bg-light {
      color: $body-color;

      &[href] {
        &:hover,
        &:focus {
          color: $body-color;
        }
      }
    }
  }

  .badge-soft-#{$color} {
    @include badge-variant-soft($value);
  }
}

.rounded-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
}

.rounded-pill.bg-primary {
  --bs-bg-opacity: 1;
  background-color: $k-dark-blue !important;
}

.rounded-pill.bg-secondary {
  --bs-bg-opacity: 1;
  background-color: $k-gray-50 !important;
}

.rounded-pill.bg-success {
  --bs-bg-opacity: 1;
  background-color: $success !important;
}

.rounded-pill.bg-info {
  --bs-bg-opacity: 1;
  background-color: $k-light-blue !important;
}

.rounded-pill.bg-warning {
  --bs-bg-opacity: 1;
  background-color: $warning !important;
}

.rounded-pill.bg-danger {
  --bs-bg-opacity: 1;
  background-color: $danger !important;
}

// Dark badge

.badge.bg-dark {
  color: $light;
}
