//
// Widgets.scss
//

.mini-stat {
  .mini-stat-img {
    width: 58px;
    height: 58px;
    line-height: 58px;
    background: rgba($white, 0.15);
    border-radius: 4px;
    text-align: center;
    img {
      max-width: 32px;
    }
  }
  .mini-stat-label {
    position: absolute;
    right: 0;
    top: 18px;
    padding: 2px 10px 2px 32px;
    clip-path: polygon(100% 0, 100% 50%, 100% 100%, 0% 100%, 24% 50%, 6% 0);
  }

  .mini-stat-label.bg-primary {
    --bs-bg-opacity: 1;
    background-color: $k-dark-blue !important;
  }

  .mini-stat-label.bg-secondary {
    --bs-bg-opacity: 1;
    background-color: $k-gray-50 !important;
  }

  .mini-stat-label.bg-success {
    --bs-bg-opacity: 1;
    background-color: $success !important;
  }

  .mini-stat-label.bg-info {
    --bs-bg-opacity: 1;
    background-color: $k-light-blue !important;
  }

  .mini-stat-label.bg-warning {
    --bs-bg-opacity: 1;
    background-color: $warning !important;
  }

  .mini-stat-label.bg-danger {
    --bs-bg-opacity: 1;
    background-color: $danger !important;
  }
}

//Sales Report

.ct-chart.wid {
  height: 240px;
}

.ct-chart.wid .ct-slice-donut {
  stroke-width: 14px !important;
}

// peity
.wid-peity {
  border-bottom: 1px solid $gray-200;
}
