//
// Range slider
//

.rc-slider-track {
  background: $primary !important;
  font-size: 11px;
}

.rc-slider-rail {
  background: $gray-300;
  border-color: $gray-300;
}

.rc-slider-handle {
  position: absolute;
  width: 14px;
  height: 14px;
  cursor: pointer;
  cursor: -webkit-grab;
  margin-top: -5px;
  cursor: grab;
  border-radius: 50%;
  border: solid 2px $primary;
  background-color: #fff;
  touch-action: pan-x;
}

.rc-slider-handle:hover {
  border-color: $secondary;
}

.rc-slider-handle:active {
  border-color: $secondary;
  box-shadow: 0 0 5px $secondary;
}

.rc-slider-handle-click-focused:focus {
  border: solid 2px #3050a2;
}
