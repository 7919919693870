.PrevetTrio {
  display: flex;
  flex-direction: column;
  gap: 0.8em;

  @include media-breakpoint-up(xl) {
    flex-direction: row;
  }

  @media print{
    flex-direction: row;
  }

  &_ShipNameImo {
    display: none;
    @media print {
      display: block;
      font-size: 1.2rem;
      margin-bottom: 1rem;
    }
  }
  &_PortStays {
    flex: 1;
    @include media-breakpoint-up(xxl) {
      flex: 1;
    }
    @media print{
      width: 100%;
      flex: 1;
    }
  }
  &_SpeedGraph {
    background-color: #ffffff;
    
    &_OceanZoneButton {
      padding: 0 0.75rem;
      height: 1.875rem;
      font-size: 0.7rem;
      @include media-breakpoint-up(xxl) {
        font-size: 0.875rem;
      }
    }
    &_Inner {
      padding: 1.25rem 1.25rem 0;
    }
    flex: 1;
    @include media-breakpoint-up(xxl) {
      flex: 1;
    }
  }
  &_Sensitivity {
    flex: 1.05;
  }
}