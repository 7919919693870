@import "./PortPredictorBreadcrumbBar";
@import "./PortPredictorCheckBoxSelector";

.PortPredictor {
  &_DangerFontColor {
    color: #f14727;
  }

  &_WarningFontColor {
    color: #fac70d;
  }

  &_SuccessFontColor {
    color: #10a689;
  }

  &_DatePicker_Input {
    max-width: 100%;
  }
  &_MarkerID {
    @include media-breakpoint-up(md) {
      display: none;
    }
    border-bottom: 2px solid;
    font-size: 1.25rem;
    margin-bottom: 0.5rem;
  }

  &_DatePicker_HolidaysList {
    padding: 0 5px;
    display: flex;
    justify-content: space-between;
    max-width: 240px;
    font-size: 10px;
  }

  &_DatePicker_HolidayError {
    font-size: 10px;
    color: #f14727;
    max-width: 240px;
  }

  &_DatePicker_Footnote_Wrapper {
    display: flex;
    flex-direction: column;
  }

  &_DatePicker_Footnote {
    font-size: 10px;
    margin: 5px;
  }

  &_DatePicker_Legend {
    display: flex;
    justify-content: space-between;
    font-size: 10px;
    &_EtaEtd {
      display: inline-block;
      width: 35px;
      height: 6px;
      background-color: #5470c6;
    }

    &_Laycan {
      display: inline-block;
      width: 14px;
      height: 14px;
      background-color: #bbc5e9;
      border-radius: 2px;
    }

    &_Holiday {
      display: inline-block;
      width: 14px;
      height: 14px;
      background-color: #ffb5b4;
      border: 2px solid #f14727;
      border-radius: 2px;
    }
  }

  &_Timeline {
    & i {
      font-weight: 900;
    }
    @include media-breakpoint-up(lg) {
      align-items: center;
    }
    max-width: 1200px;
    display: flex;
    flex-direction: column;
    @include media-breakpoint-up(xxl) {
      max-width: 1600px;
    }
    @include media-breakpoint-up(xxxl) {
      max-width: 2500px;
    }
  }
  &_Timeline_Inner {
    @include media-breakpoint-up(lg) {
      align-items: center;
    }
    display: flex;
    flex-direction: column;
  }

  &_InputsContainer {
    @include media-breakpoint-up(lg) {
      width: 450px;
    }
  }

  &_Marker {
    display: flex;
    flex-direction: column;

    @include media-breakpoint-up(lg) {
      flex-direction: row;
    }
  }
  &_MarkerSpacer {
    margin-left: 1rem;
  }

  &_AddMarkerButton {
    @include media-breakpoint-up(lg) {
      // margin-right: 312px;
      border-radius: 100%;
      width: 40px;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding-right: 11px;
    }
    width: 100%;
  }

  &_DeleteMarkerButtonWrapper {
    display: flex;

    @include media-breakpoint-up(lg) {
      justify-content: end;
    }
  }

  &_DeleteMarkerButton {
    height: 30px;
    width: 30px;
    display: flex;
    align-items: center;

    &_Icon {
      position: relative;
      right: 6px;
    }
  }

  &_MarkerArrivalInputs {
    @include media-breakpoint-up(lg) {
      display: flex;
      flex-direction: row-reverse;
    }
  }

  &_MarkerInputContainer {
    margin-bottom: 1.8rem;
    @include media-breakpoint-up(lg) {
      // width: 400px;
    }
    font-size: 0.95rem;
  }

  &_MarkerCenter {
    display: none;
    @include media-breakpoint-up(lg) {
      display: flex;
      flex-direction: column;
      align-items: center;

      &_Line {
        border-left: 1px solid #000;
        height: 100%;
      }
      &_IconWrapper {
        width: 30px;
        height: 34px;
        border: 1px solid #000000;
        border-radius: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }

  &_MarkerDisplayContainer {
    @include media-breakpoint-up(lg) {
      width: 450px;
      font-size: 0.95rem;
      margin-left: 1rem;
      margin-bottom: 0;
    }

    font-size: 0.85rem;
    margin-bottom: 1rem;
  }

  &_MarkerDisplayTitle {
    @include media-breakpoint-up(lg) {
      display: none;
    }
    display: block;
    font-size: 0.9rem;
    font-weight: bold;
    border-bottom: 1px solid #999999;
  }

  &_InputWrapper {
    position: relative;
    @include media-breakpoint-up(lg) {
      display: flex;
      align-items: center;
      justify-content: right;
    }
  }

  &_InputAlert {
    position: absolute;
    left: 100%;
  }

  &_MinMaxMedianButtonsWrapper {
    @include media-breakpoint-up(lg) {
      flex-direction: unset;
    }
    display: flex;
    // flex-direction: row-reverse;
    align-items: center;
  }

  &_MinMaxMedian_Popover {
    &.btn {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 35px;
    }
  }

  &_MinMaxMedianButtons {
    @include media-breakpoint-up(lg) {
      justify-content: right;
      max-width: 260px;
    }
    display: flex;
    align-items: center;
  }

  &_MinMaxMedianInfoIcon_Wrapper {
    @include media-breakpoint-up(lg) {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 15px;
      position: relative;
      top: -6px;
    }
    display: none;
  }

  &_InputGroup {
    .form-control {
      font-size: 0.95rem;
    }

    @include media-breakpoint-up(lg) {
      width: 270px;
    }
  }

  &_Select {
    @include media-breakpoint-up(lg) {
    }
  }

  &_FormLabel {
    @include media-breakpoint-up(lg) {
      text-align: end;
      margin-left: 1rem;
    }

    margin: 0 0.95rem 0 0;
    width: auto;
  }

  &_TurnAroundTimeChart {
    display: none;
    @include media-breakpoint-up(lg) {
      display: block;
    }
  }

  &_LaycanBadge {
    display: flex;
    align-items: center;
    margin-bottom: 0.5rem;
    justify-content: center;
    padding: 1px 4px;
    border-radius: 4px;
    font-size: 0.7rem;
    min-width: 150px;
    max-width: 200px;
    & i {
      font-size: 0.7rem;
      bottom: 1px;
      position: relative;
    }
    &.bg-danger,
    &.bg-success {
      color: #ffffff;
    }
  }

  &_SpeedGraphModal {
    margin: 0.95rem;
  }

  &_DisplayMobileLabel {
    @include media-breakpoint-up(lg) {
      display: none;
    }
    display: block;
    font-weight: bold;
  }

  &_Display {
    & .accordion {
      width: 180px;
      margin-bottom: 0.5rem;
      margin-top: 0.5rem;
    }
    & .accordion-header {
      font-size: 0.95rem;
    }

    & .accordion-button {
      padding: 0;
      font-size: 0.95rem;
    }

    & .accordion-body {
      padding: 0.25rem 0;
      cursor: pointer;
    }
  }

  &_DisplayArrival_Port {
    @include media-breakpoint-up(lg) {
      align-items: center;
    }
  }

  &_TurnAroundTimeCheckboxes_Wrapper {
    margin-left: 1.9rem;
  }

  &_AlertWarning {
    background-color: transparent;
    color: #fac712;
    font-size: 18px; // Adjust exclamation size
  }
  &_AlertDanger {
    background-color: transparent;
    color: #f14725;
    font-size: 18px; // Adjust exclamation size
  }

  &_FontInvalid {
    color: #dedede;
  }
  &_DepartureDate {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  &_CargoCircle_Wrapper {
    display: flex;
    flex-direction: column;
    height: 38px;
    justify-content: center;
  }
  &_CargoCircle {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: transparent;
    border: 1px solid #545454;

    &--yellow {
      background-color: #fac712;
    }
    &--green {
      background-color: green;
    }
  }
  &_HolidayTable {
    font-size: 12px;
    & td,
    & th {
      padding: 0.25rem; // Adjust the padding
    }
  }
}

.dropdown-menu.show {
  z-index: 1001;
}

// Wrap all custom datepicker styles in the parent wrapper
.PortPredictor_DatePicker_Wrapper {
  @include media-breakpoint-up(lg) {
    display: flex;
    max-width: 270px;
    & .react-datepicker-wrapper {
      min-width: 120px;
    }
  }
  .react-datepicker {
    display: flex;
    flex-direction: column;
    &__day,
    &__day-name {
      width: 2.5rem;
      height: 2.5rem;
      line-height: 2.5rem;
    }
    &__isEtaEtd {
      position: relative;

      &::before {
        content: "";
        position: absolute;
        top: 4px; // Adjust as needed to position the dot
        left: 50%;
        transform: translateX(-50%);
        width: 54px;
        height: 6px;
        background-color: #5470c6; // Dot color
      }
    }

    &__holiday {
      background-color: rgb(255 105 105 / 50%);
      color: white;
      border-radius: 5px;
      border: 3px solid rgb(255 105 105 / 100%);
    }
    &__holiday-day {
      position: relative;
      cursor: pointer;
      &:hover .react-datepicker__holiday-tooltip {
        display: block;
      }
    }
    &__holiday-tooltip {
      display: none;
      position: absolute;
      top: -30px;
      left: 50%;
      transform: translateX(-50%);
      background-color: #ff9c9c;
      padding: 2px;
      color: #ffffff;
      border-radius: 3px;
      white-space: nowrap;
      z-index: 1;
      font-size: 12px;
    }
  }

  .react-datepicker__day--in-selecting-range,
  .react-datepicker__day--in-range {
    background-color: rgb(84 112 198 / 40%);
  }

  .react-datepicker__day--in-selecting-range:hover,
  .react-datepicker__day--in-range:hover {
    background-color: rgb(84 112 198 / 40%) !important;
  }

  .react-datepicker__day--selected,
  .react-datepicker__day--in-selecting-range,
  .react-datepicker__day--in-range {
    background-color: rgb(84 112 198 / 40%) !important;
  }

  .react-datepicker {
    display: flex;
    flex-direction: column;
  }

  .react-datepicker__day--keyboard-selected,
  .react-datepicker__month-text--keyboard-selected,
  .react-datepicker__quarter-text--keyboard-selected,
  .react-datepicker__year-text--keyboard-selected {
    background-color: transparent;
    color: #000;
  }
}
