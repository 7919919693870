// Variables
$mobile-breakpoint: 768px;
$tablet-breakpoint: 1024px;
$desktop-breakpoint: 1440px;
$gap-size: 10px;

// Mixins for breakpoints
@mixin mobile {
  @media screen and (max-width: $mobile-breakpoint) {
    @content;
  }
}

@mixin tablet {
  @media screen and (min-width: #{$mobile-breakpoint + 1px}) and (max-width: $tablet-breakpoint) {
    @content;
  }
}

@mixin small-desktop {
  @media screen and (min-width: #{$tablet-breakpoint + 1px}) and (max-width: $desktop-breakpoint) {
    @content;
  }
}

@mixin large-desktop {
  @media screen and (min-width: #{$desktop-breakpoint + 1px}) {
    @content;
  }
}

// Card container styles
.PrevetCards {
  display: grid;
  gap: $gap-size;
  width: 100%;

  // Responsive layouts
  @include mobile {
    grid-template-columns: 1fr;
  }

  @include tablet {
    grid-template-columns: repeat(2, 1fr);
  }

  @include small-desktop {
    grid-template-columns: repeat(3, 1fr);
  }

  @include large-desktop {
    grid-template-columns: repeat(4, 1fr);
  }
  &_CircVesselForm {
    font-size: 0.7rem;
    padding: 5px;
    height: 25px;
    min-width: 25px;
    background-color: #f5f5f5;
  }
  &_CircVesselText {
    font-size: 0.7rem;
    &_SubText {
      position: absolute;
      font-size: 0.6rem;
      color: gray;
      top: -9px;
      left: 8px;
      flex-wrap: nowrap;
      &_SeaDays {
        top: -12px;
        font-size: 0.6rem;
        position: absolute;
        color: gray;
        flex-wrap: nowrap;
        left: 5px;
      }
    }
  }
}

.PrevetVesselTradingModal {
  width: 100vw;
  max-height: 90vh;
  margin: 0 auto;
  padding: 0;
  overflow: hidden;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  &_Row {
    display: flex;
    height: 100%;
  }
  &_Map {
    flex: 4;
  }
  &_Info {
    background-color: white;
    flex: 1;
    padding: 1rem;
    z-index: 1000;
    position: absolute;
    width: 25%;
    right: 0;
    top: 55px;
  }
}

.dash {
  width: 18px;
  height: 4px;
  border-radius: 0%;
  margin-right: 8px;
  display: inline-block;
}

.circle {
  width: 18px;
  height: 18px;
  border-radius: 50%;
  margin-right: 8px;
  display: inline-block;
}

.hide-on-small-screen {
  @media (max-width: 1880px) {
    display: none;
  }
  @media (max-width: 1560px) {
    display: block;
  }
}

.PrevetCardHolder {
  display: flex;
  flex-direction: column; //change this back to column
  justify-content: space-between;
  gap: 0.8em;
  @media (min-width: 1660px) {
    flex-direction: row;
  }
}

.TopContainer {
  display: flex;
  flex: 2;
  gap: 0.8em;
  flex-direction: row;
  @media (min-width: 2140px){
    flex: 1.5
  }
  @media (max-width: 1023px) { //change back to 767 if needed
    flex-direction: column;
  }
  &_SearchWidget {
    flex: 2;
  }
  &_ProlongedStayWidget {
    flex: 1;
  }
  &_DDWidget {
    flex: 1.25;
  }
}

.BottomContainer {
  display: flex;
  flex: 1;
  gap: 0.8em;
  flex-direction: row;
  @media (max-width: 1023px) { //change back to 767 if needed
    flex-direction: column;
  }
  &_CollapsibleContainer {
    display: flex;
    gap: 0.8em;
    flex: 1.5;
/*     @media (max-width: 1880px) {
      flex: 1;
    } */
  }
  &_CollapsibleWidget {
    flex: 1;
  }
}

.DatePickerText{
  width: 100%;
  padding-left:70px;
}
