@import "./PrevetRightShip";
@import "./PrevetDryDockBadge";
@import "./PrevetVesselHistory";
@import "./PrevetTrio";
@import "./PrevetWrapperBreadcrumbBar";
@import "./PrevetVesselTradingModal";
@import "./PrevetSensitivityCalculator";
@import "./PrevetCards";

.SensitivityBenchmark {
  &_Wrapper {
    border-radius: 5px;
    background: #f8f9fa;
    margin-bottom: 1rem;
    padding: 1rem 1rem 0.75rem;
  }
  &_Data {
    line-height: 0.9rem;
  }
}

.PrevetSensitivity {
  &_SeaDaysInput {
    display: flex;

    padding-left: 0.5rem;
    margin-bottom: 0.4rem;
    align-items: center;
    margin-top: 1.6rem;

    &_Label {
      flex: 1;
      margin: 0.75rem 0.3rem;
    }

    &_Control {
      flex: 2;
    }
  }
}

.PrevetSensitivityCalculator_Table {
  .tbody-tr {
    height: 55px;

    .tbody-td {
      vertical-align: middle;
    }
  }
}

.PrevetMain {
  &_SearchBar {
    @media print {
      display: none;
    }
  }
}

.BottomCardContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 1em;
  @media (max-width: 1660px) {
    flex-direction: column;
  }
}

@media print {
  /* Base page setup */
  @page {
    size: 2140px 1508px;
    margin: 0;
  }

  /* Force desktop layout */
  html,
  body {
    width: 2140px !important;
    min-width: 2140px !important;
    margin: 0 !important;
    padding: 0 !important;
  }

  /* Container styles */
  .PrevetContainer {
    width: 2140px !important;
    min-width: 2140px !important;
    margin: 0 !important;
    padding: 0 !important;
  }

  /* Target the ReactEcharts container */
  .echarts-container {
    width: 100vw !important; /* Full viewport width */
    max-width: 100% !important;
    height: auto !important;
  }

  /* Ensure the chart resizes correctly */
  canvas {
    width: 100% !important;
    height: auto !important;
  }

  /* Force desktop layout for flex containers */
  .PrevetCardHolder {
    display: flex !important;
    flex-direction: row !important;
    width: 100% !important;
    gap: 0.8em !important;
  }

  .TopContainer {
    display: flex !important;
    flex-direction: row !important;
    flex: 2 !important;
    gap: 0.8em !important;
  }

  .BottomContainer {
    display: flex !important;
    flex-direction: row !important;
    flex: 1 !important;
    gap: 0.8em !important;
  }

  .BottomContainer_CollapsibleContainer {
    display: flex !important;
    flex: 1.5 !important;
    gap: 0.8em !important;
  }

  .BottomCardContainer {
    display: flex !important;
    flex-direction: row !important;
    gap: 1em !important;
  }

  /* Override any mobile-specific media queries */
  .TopContainer_SearchWidget {
    flex: 2 !important;
  }

  .TopContainer_ProlongedStayWidget {
    flex: 1 !important;
  }

  .TopContainer_DDWidget {
    flex: 1.25 !important;
  }

  /* Force all media query breakpoints to use desktop layout */
  @media (max-width: 1880px), (max-width: 1660px), (max-width: 767px) {
    .PrevetCardHolder,
    .TopContainer,
    .BottomContainer,
    .BottomContainer_CollapsibleContainer,
    .BottomCardContainer {
      flex-direction: row !important;
    }
  }

  /* Ensure content prints properly */
  * {
    -webkit-print-color-adjust: exact !important;
    print-color-adjust: exact !important;
  }

  /* Hide print-specific elements */
  .PrevetMain_SearchBar {
    display: none !important;
  }

  /* Prevent unwanted page breaks */
  .card {
    break-inside: avoid;
  }
}
